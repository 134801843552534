import { isDefined } from "@clipboard-health/util-ts";
import { locationTrackingHelper } from "@src/app/locationTracking/locationTracking.helper";
import { useRegisterLocationUpdateListener } from "@src/app/locationTracking/useRegisterLocationUpdateListener";
import { environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useEffect } from "react";

import { locationService } from "../openShifts/urgentShifts/locationService";
import { useDefinedAgent } from "../store/helperHooks";

export const useRegisterLocationTracking = () => {
  const ldFlags = useCbhFlags();
  const agent = useDefinedAgent();
  const agentId = agent.userId;

  const isUrgentShiftsEnabled = ldFlags[CbhFeatureFlag.URGENT_SHIFTS];
  const trackingOptions = ldFlags[CbhFeatureFlag.RADAR_SDK_CONFIG_ONSITE_TRACKING];
  const shouldSkipIosBackgroundTask = useCbhFlag(CbhFeatureFlag.SKIP_LOCATION_BACKGROUND_IOS, {
    defaultValue: false,
  });

  const registerLocationUpdateListener = useRegisterLocationUpdateListener();

  useEffect(() => {
    locationService.initialize(environmentConfig.REACT_APP_RADAR_SDK_PUBLISHABLE_KEY);
  }, []);

  useEffect(() => {
    if (!isDefined(agentId)) {
      return;
    }

    locationService.setUserId(agentId);
  }, [agentId]);

  useEffect(() => {
    if (!isUrgentShiftsEnabled) {
      locationService.stopTracking();
    }
  }, [isUrgentShiftsEnabled]);

  useEffect(() => {
    if (!isDefined(agentId) || !isUrgentShiftsEnabled) {
      return;
    }

    const cleanupFunctions = [] as Array<VoidFunction>;

    const registerListeners = async () => {
      cleanupFunctions.push(await locationTrackingHelper.registerAppOpenEventListener(agentId));

      cleanupFunctions.push(
        await registerLocationUpdateListener({ agentId, shouldSkipIosBackgroundTask })
      );

      cleanupFunctions.push(
        await locationTrackingHelper.registerSilentNotificationListener({
          agentId,
          shouldSkipIosBackgroundTask,
        })
      );

      cleanupFunctions.push(
        await locationTrackingHelper.registerLocationEventListener(trackingOptions)
      );
    };

    void locationTrackingHelper.handleCommuteTopicSubscription(agentId);

    void registerListeners();

    return () => {
      cleanupFunctions.forEach((cleanupListener) => cleanupListener());
    };
  }, [
    agentId,
    trackingOptions,
    isUrgentShiftsEnabled,
    registerLocationUpdateListener,
    shouldSkipIosBackgroundTask,
  ]);
};
